import React from "react";

import fr from "./fr.json"
import en from "./en.json"


const translateManager = {
  
    getText: (key) => {
    
        // current lang in browser
        const lang = translateManager.getCurrentLang();

        // defautl lang is FR
        let currentLangList = fr;
        if(lang == "en-US") {
            currentLangList = en;
        }

        // get text from listing with key
        let text = currentLangList[key];

        // if key is not translates we get it in default lang
        if(text == null) {
            text = fr[key];
        }
        
        // return text
        return text;
    },

    getCurrentLang: () => {
        let  currentLang = "fr-FR"
        if(typeof window !== 'undefined' && window.navigator && window.navigator.language) {
            currentLang = localStorage.getItem("selected_lang");
            console.log("current lang from localStorage : " + currentLang);
            if(currentLang == null) {
                currentLang = window.navigator.language;
                console.log("current lang from browser : " + currentLang);
                translateManager.setLang(currentLang);
                // localStorage.setItem("selected_lang", currentLang);
                
            }
        }
        return currentLang;
    },

    getLangSelector: () => {

        return (
            <div style={{"textAlign": "right"}}>
                {translateManager.getlangIcon("en-US", "US")}
                {translateManager.getlangIcon("fr-FR", "FR")}
               
            </div>
            );

    },

    setLang: (lang) => {
        console.log("set lang : ", lang);
        if(typeof window !== 'undefined' && window.navigator && window.navigator.language) {
            localStorage.setItem("selected_lang", lang);
            window.location.reload();
        }
    },

    getlangIcon: (_lang, flagName) => {

        const currentLang = translateManager.getCurrentLang();

        let isClickable  = currentLang != _lang;
        //let isClickable  = true;

        const onClickFlag = (lang) => {
            console.log("click lang : ", lang);
            translateManager.setLang(lang)
            // if(typeof window !== 'undefined' && window.navigator && window.navigator.language) {
            //     localStorage.setItem("selected_lang", lang);
            //     window.location.reload();
            // }
        };

        const flagStyle = {"width": "20px", "margin": ".1em", "opacity": isClickable ? 1 : 0.5, "cursor": isClickable ? "pointer" : "default"};
        const url = "http://purecatamphetamine.github.io/country-flag-icons/3x2/" + flagName + ".svg";

        return (<img
            onClick={() => {if(isClickable){ onClickFlag(_lang)}} }
            style={flagStyle}
            alt= {_lang}
            src={url}/>);
    }
    
};


export default translateManager;