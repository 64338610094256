import React from 'react'

import Header from "./Header"
import Footer from "./Footer"

import '../styles/global.css'
import bg from '../images/background-image.svg';
import { height } from '@mui/system';

export default function Layout({children}) {
    return (
        <div style={{
            backgroundColor: "#201C54",
            height: "100%"
            }}>  
            {/* <Header /> */}
            <div className="content-section" style={{backgroundImage: 'url("' + bg + '")', backgroundRepeat: "no-repeat", backgroundSize: "cover"}}>
                <div className="content" >
                    { children }
                </div>
            </div>
            {/* <Footer /> */}
        </div>
    )
}
